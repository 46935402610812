export const configColumn = [
  {
    key: 'user_name',
    label: 'Nama User',
    class: 'p-1 align-middle',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '200px',
    },
    tdClass: 'text-black',
  },
  {
    key: 'qty',
    label: 'Jumlah Paket',
    class: 'p-1 align-middle text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'no_resi',
    label: 'Resi Sample',
    class: 'p-1 align-middle',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'pickup_city',
    label: 'Kota Jemput',
    class: 'p-1 align-middle',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'pickup_request_date',
    label: 'Pengajuan',
    class: 'p-1 align-middle',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',

  },
  {
    key: 'delay',
    label: 'Delay Pickup',
    class: 'p-1 align-middle',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'order_status',
    label: 'Status',
    class: 'p-1 align-middle',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
]

export const statusType = [
  {
    label: 'Semua',
    onCheck: false,
  },
  {
    label: 'Dipickup',
    value: 'Dipickup',
    onCheck: false,
  },
  {
    label: 'Belum Dipickup',
    value: 'Belum Dipickup',
    onCheck: false,
  },
]
