<template>
  <BCard>
    <BRow class="items-center p-2">
      <BCol lg="6">
        <strong class="text-black text-bold text-2xl">{{ title }}</strong>
      </BCol>
      <BCol
        lg="6"
        class="flex justify-end items-center"
      >
        <BFormInput
          v-model="search"
          placeholder="Ketik nama user atau resi"
          style="width: 45%; margin-right: 10px"
          @input="searchData"
        />
        <BButton
          v-b-modal.modal-download
          variant="primary"
          size="sm"
        >
          <img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/document-download.svg"
            alt="doc-download"
            class="inline-block mr-[4px]"
            width="18"
          >
          Download Request Pickup
        </BButton>
      </BCol>
    </BRow>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        id="table"
        :items="items"
        :fields="fields"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        class="mt-1"
      >
        <template #cell(pickup_request_date)="data">
          <div class="text-black text-md min-w-[150px]">
            {{ DAY_MONTH_YEAR(data.value) }}
          </div>
          <div class="text-[#828282]">
            {{ TIME(data.value) }} WIB
          </div>
        </template>
        <template #cell(user_name)="data">
          <div class="flex items-center">
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/whatsapp.svg"
              alt="Komerce"
              style="cursor: pointer"
              @click="handlePhone(data)"
            >
            <span class="ml-[5px]">{{ data.value }}</span>
          </div>
        </template>
        <template #cell(qty)="data">
          <div class="min-w-[150px]">
            {{ data.value }}
          </div>
        </template>
        <template #cell(no_resi)="data">
          <div class="flex items-center">
            <span>{{ data.value }}</span>
            <img
              v-b-tooltip.hover.top="'Copy resi'"
              src="https://storage.googleapis.com/komerce/assets/copy.svg"
              alt="Komerce"
              class="ml-[5px] cursor-pointer"
              @click="copyResi(data.value)"
            >
          </div>
        </template>
        <template #cell(pickup_city)="data">
          <div class="flex items-center min-w-[150px]">
            <span>{{ data.value }}</span>
            <img
              v-b-tooltip.hover.top="'Copy Detail Pickup'"
              src="https://storage.googleapis.com/komerce/assets/copy.svg"
              alt="Komerce"
              class="ml-[5px] cursor-pointer"
              @click="copyDetailData(data.item)"
            >
          </div>
        </template>
        <template #cell(delay)="data">
          <div class="min-w-[150px]">
            {{ handleDelayAction(data.value) }}
          </div>
          <div
            v-if="data.item.delay_status === 'Kedepan'"
            class="text-white text-center mt-50"
            style="background: #FBA63C;
border-radius: 12px; max-width: 80px;"
          >
            Kedepan
          </div>
          <div
            v-if="data.item.delay_status === 'Terlewati'"
            class="text-white text-center mt-50"
            style="background: #E31A1A;
border-radius: 12px; max-width: 80px;"
          >
            Terlewati
          </div>
        </template>
        <template #cell(order_status)="data">
          <span :class="handleClassStatus(data.value)">{{ data.value }}</span>
        </template>
      </BTable>
    </BOverlay>
    <BModal
      id="modal-download"
      title="BootstrapVue"
      centered
      hide-footer
      hide-header
      @hide="progress = null"
    >
      <div class="py-[56px] px-[32px]">
        <div class="space-y-[24px]">
          <div class="space-y-[16px] ">
            <div class="text-center text-[24px] font-semibold text-[#333]">
              Download Data Request Pickup
            </div>
            <div class="text-center text-[#333] text-[16px]">
              Download data semua permintaan pickup dari semua user di range waktu yang dipilih
            </div>
          </div>
          <div class="space-y-[8px]">
            <div class="text-[#333]">
              Pilih Rentang
            </div>
            <DateRangePicker
              ref="picker"
              v-model="dateRange"
              :locale-data="locale"
              :ranges="ranges"
              :opens="'center'"
              class="w-full"
              :date-format="dateFormat"
            >
              <template
                v-slot:input="picker"
              >
                <div>
                  {{ SLASHED_DDMMYYYY(picker.startDate) }} - {{ SLASHED_DDMMYYYY(picker.endDate) }}
                </div>
              </template>
            </DateRangePicker>
          </div>
          <BRow
            v-if="progress !== null"
            align-v="center"
          >
            <BCol cols="12">
              <div class="text-[#333] text-[12px]">
                Sedang memuat File
              </div>
            </BCol>
            <BCol>
              <BRow
                align-v="center"
              >
                <BCol cols="10">
                  <BProgress
                    height="11px"
                    variant="success"
                    :value="progress"
                  />
                </BCol>
                <BCol cols="2">
                  <div class="text-[#333] text-[12px]">
                    {{ progress }}%
                  </div>
                </bcol>
              </BRow>
            </BCol>
          </BRow>
          <BRow>
            <BCol cols="6">
              <BButton
                variant="outline-primary"
                class="w-full"
                @click="$bvModal.hide('modal-download')"
              >
                Batal
              </BButton>
            </BCol>
            <BCol cols="6">
              <BButton
                :variant="loadingDownload ? 'secondary' : 'primary'"
                class="w-full"
                :disabled="loadingDownload"
                @click="downloadMonitoringPickup"
              >
                Download
              </BButton>
            </BCol>
          </BRow>
        </div>
      </div>
    </BModal>
  </BCard>
</template>

<script>
import moment from 'moment'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { DAY_MONTH_YEAR, TIME, SLASHED_DDMMYYYY } from '@/libs/filterDate'
import { komshipAxiosIns, newAxiosIns } from '@/libs/axios'
import { MONITORING_PICKUP, DOWNLOAD_MONITORING_PICKUP } from '@/provider/url'
import chatButton from '@/libs/chatButton'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  today,
  last7,
  last30,
  firstDateOfMonth,
  komshipDate,
  last2,
  last3,
} from '@/store/helpers'
import secureLocalStorage from '@/libs/secureLocalstorage'
import { configColumn, statusType } from './config'

export default {
  components: {
    DateRangePicker,
  },
  data() {
    return {
      progress: null,
      ekspedisi: secureLocalStorage.get('userData').mitras.owner,
      loadingDownload: false,

      chatButton,
      title: 'Monitoring Pickup',
      loading: true,
      filterStatus: [],
      search: '',
      statusType,
      items: [],
      fields: configColumn,
      DAY_MONTH_YEAR,
      SLASHED_DDMMYYYY,
      TIME,
      limit: 30,
      offset: 0,
      lastData: false,

      dateRange: {
        startDate: last2,
        endDate: last2,
      },
      locale: {
        format: 'dd/mm/yyyy',
      },
      ranges: {
        'Hari Kemarin': [last2, last2],
        '2 Hari Sebelumnya': [last3, last2],
        'Custom ': [komshipDate, today],
      },
      today,
      last7,
      last30,
      last2,
      last3,
      komshipDate,
      firstDateOfMonth,
    }
  },
  watch: {
    currentPage: {
      handler() {
        this.getData()
      },
    },
    dateRange: {
      handler() {
        this.progress = null
      },
    },
  },
  created() {
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.getElementById('table').offsetHeight && !this.loading) {
        this.getNextData()
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      this.offset = 0
      const params = {
        limit: this.limit,
        offset: this.offset,
        search: this.search,
      }
      await komshipAxiosIns.get(MONITORING_PICKUP, { params })
        .then(res => {
          const { data } = res.data
          this.items = data
          this.loading = false
          this.offset = data.length
          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.message,
              variant: 'danger',
            },
          }, 2000)
          this.loading = false
        })
    },
    async getNextData() {
      if (!this.lastData) {
        this.loading = true
        const params = {
          limits: this.limit,
          offset: this.offset,
          search: this.search,
        }
        await komshipAxiosIns.get(MONITORING_PICKUP, { params })
          .then(result => {
            const { data } = result.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: err.response.message,
                variant: 'danger',
              },
            })
          })
      }
    },
    searchData: _.debounce(function search() {
      this.getData()
    }, 1000),
    handleLableStatus(value) {
      if (value === 1) {
        return 'Dipacking'
      }
      if (value === 2) {
        return 'Dikirim'
      }
      return '-'
    },
    handleClassStatus(value) {
      if (value === 'Dipacking') {
        return 'send'
      }
      if (value === 'Dikirim') {
        return 'packing'
      }
      return ''
    },
    handleDelayAction(value) {
      if (value) {
        return value
      }
      return '-'
    },
    copyResi(value) {
      navigator.clipboard.writeText(value)
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: 'Resi berhasil disalin',
          icon: 'CheckIcon',
          variant: 'success',
        },
      }, 1000)
    },
    copyDetailData(value) {
      const username = `Nama Penanggung Jawab: ${value.user_name}`
      const phoneNumber = `No.Hp: ${value.pickup_detail.pic_phone}`
      const resi = `Resi sample: ${value.no_resi}`
      const createdAt = `Pengajuan Pickup: ${moment(value.pickup_detail.pickup_date).format('DD MMMM YYYY')} Pukul ${value.pickup_detail.pickup_time} WIB`
      const pickupcity = `Kota Pickup: ${value.pickup_detail.city}`
      const address = `Alamat: ${value.pickup_detail.address_detail}`
      const data = `${username}\n${phoneNumber}\n${address}\n${pickupcity}\n${resi}\n${createdAt}`
      navigator.clipboard.writeText(data)
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: 'Data berhasil disalin',
          icon: 'CheckIcon',
          variant: 'success',
        },
      }, 1000)
    },
    async downloadMonitoringPickup() {
      this.progress = 0
      this.loadingDownload = true
      await newAxiosIns.get(`komship/api/${DOWNLOAD_MONITORING_PICKUP}`, {
        params: {
          shipping: this.ekspedisi,
          start_date: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
          end_date: moment(this.dateRange.endDate).format('YYYY-MM-DD'),
        },
        onDownloadProgress: progressEvent => {
          if (progressEvent.lengthComputable) {
            // Calculate progress as a percentage
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)

            // Ensure that progress is between 0 and 100
            this.progress = Math.min(100, Math.max(0, progress))
          }
        },
      }).then(res => {
        this.loadingDownload = false
        const { data } = res.data

        // base64 xlsx
        const base64 = data
        const byteCharacters = atob(base64)
        const byteNumbers = new Array(byteCharacters.length)
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const blobURI = URL.createObjectURL(blob)

        // create a temporary element to hold our dataURI
        const tempLink = document.createElement('a')
        tempLink.href = blobURI
        tempLink.setAttribute('download', `Request_Pickup_${this.ekspedisi}_${moment(this.dateRange.startDate).format('DD-MM-YYYY')}_${moment(this.dateRange.endDate).format('DD-MM-YYYY')}.xlsx`)
        tempLink.click()
      }).catch(err => {
        this.loadingDownload = false
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Gagal',
            icon: 'AlertCircleIcon',
            text: 'Tidak ada data yang ditampilkan',
            variant: 'danger',
          },
        })
        this.progress = null
      })
    },
    dateFormat(classes, date) {
      if (!classes.disabled) {
        // eslint-disable-next-line no-param-reassign
        classes.disabled = date.getTime() > new Date()
      }
      return classes
    },
    handlePhone(value) {
      const phoenNumber = value.item.user_phone
      window.open(`https://wa.me/62${phoenNumber.substring(1)}`, '_blank')
    },
  },
}
</script>
<style scoped>
.send {
  color: #08a0f7;
  border: 1px solid #08a0f7;
  border-radius: 10px;
  padding: 0 10px;
  background: #dff3ff;
}
.packing {
  color: #fbbc05;
  border: 1px solid#fbbc05;
  border-radius: 10px;
  padding: 0 10px;
  background: #f8ecc8;
}

.card-body {
  padding: 0;
}
</style>
